























import Vue from "vue";
import { letterKeydown } from "@/validator/globalvalidator";
import { batchService } from "@service/batch.service";
import { ResponseBatch } from "@interface/batch.interface";

export default Vue.extend({
  name: "CGenerateBatchNumber",
  props: {
    value: {
      type: String,
      required: true,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      vmBatchNumber: "",
      loading: false
    };
  },
  methods: {
    letterKeydown,
    findBatchNumber(batchNumber: string): Promise<ResponseBatch> {
      return batchService.findByBatchNumber(batchNumber);
    },
    async onSearch(search: string): Promise<void> {
      try {
        if (search) {
          this.loading = true;
          const batch = await this.findBatchNumber(search);
          this.vmBatchNumber = "";
          this.$emit("on-search", { batch });
        }
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading = false;
      }
    },
    onInput(val): void {
      this.$emit("input", val.target.value);
    }
  }
});

